import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
// import components
import CallToAction from "components/CallToAction"

const ParagraphCallToAction = ({
   content: { fieldTitle, fieldLink, fieldMediaImage, fieldDescription },
 }) => (
   <CallToAction
     backgroundImage={fieldMediaImage.entity.fieldMediaImage.callToAction.url}
     title={fieldTitle}
     description={fieldDescription}
     linkUri={fieldLink.uri}
     linkText={fieldLink.title}
   />
)

export const fragment = graphql`
  fragment ParagraphCallToAction on Drupal_ParagraphCallToAction {
    fieldTitle
    fieldLink {
      title
      uri
    }
    fieldMediaImage {
      entity {
        ...MediaImage
      }
    }
    fieldDescription
  }
`

ParagraphCallToAction.propTypes = {
  content: PropTypes.object,
}

export default ParagraphCallToAction
