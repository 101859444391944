import React from "react"
import { FaLink } from "react-icons/fa"

const Card = ({ title, subtitle, imageUrl, linkUrl, linkText }) => (
  <div className="card">
    {imageUrl && <img src={imageUrl} className="img-fluid" />}

    <div className="content">
      {title && (
        <div className="title">
          {title}
        </div>
      )}

      {subtitle && (
        <div className="subtitle">
          {subtitle.substr(0, 76) + '...'}
        </div>
      )}
    </div>

    {linkUrl && <a href={linkUrl} />}

    {linkText && (
      <div className="link">
        <button>
          {linkText}
        </button>
      </div>
    )}
  </div>
)

export default Card
