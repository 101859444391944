import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "antd"
import StyledSectionMap from "./styles"
import ButtonCustom from "../Button"

const SectionMap = ({ title, description, image, linkUrl, linkText }) => (
  <StyledSectionMap imageUrl={image}>
    <Row type="flex">
      <Col lg={16} md={24} sm={24}>
        { image && <div className="image-bg" />}
      </Col>

      <Col className="bgcolor" lg={8} md={24} sm={24}>
        <div className="wrapper">
          {title && <div className="title">{title}</div>}
          {description && <div className="text">{description}</div>}
          {linkText && linkUrl && (
            <ButtonCustom
              link={linkUrl}
              type="secondary"
            >
              {linkText}
            </ButtonCustom>
          )}
        </div>
      </Col>
    </Row>
  </StyledSectionMap>
  )

SectionMap.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  linkUri: PropTypes.string,
  linkText: PropTypes.string,
}

export default SectionMap
