import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { Col, Row } from "antd"
import TabsImage from "images/Tabs/TabsVerticalBar.png"
import StyledMegaMenu from "./styles"

const MegaMenu = ({ image, menu }) => {
  const megaMenuContent = menu.map(mainMenuLink => {
    const mainMenuLinkTitle = mainMenuLink.entity.fieldTitle
    const mainMenuLinkUrl =
      mainMenuLink.entity.fieldMenuLink?.entity.entityUrl.path
    const widthCol = Math.round(24 / menu.length)

    const subMenuLinks = mainMenuLink.entity.fieldSubMenuLinks?.map(
      subMenuLink => {
        const subLinkTitle = subMenuLink.entity.fieldTitle
        const subLinkUrl =
          subMenuLink.entity.fieldMenuLink?.entity.entityUrl.path

        return <Link to={subLinkUrl}>{subLinkTitle}</Link>
      }
    )

    return (
      <Col lg={widthCol} md={widthCol} sm={24} xs={24}>
        <div className="title">
          <Link to={mainMenuLinkUrl}>{mainMenuLinkTitle}</Link>
        </div>

        <div className="content">{subMenuLinks}</div>
      </Col>
    )
  })

  return (
    <StyledMegaMenu tabsImage={TabsImage} imageUrl={image}>
      <div className="mega-menu">
        <Row type="flex">
          <Col lg={6} md={6} sm={24} xs={24}>
            {image && <div className="image-bg"/> }
          </Col>

          <Col lg={18} md={18} sm={24} xs={24} className="tatoo">
            <div className="mega-menu-content">
              <Row type="flex" gutter={[32, 32]}>
                {megaMenuContent}
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </StyledMegaMenu>
  )
}

export default MegaMenu
