import styled from "styled-components"

export default styled.div`
  font-family: ${({ theme }) => theme.fontFamily.second};
  padding: 50px;
  width: 100%;
  height: auto; 
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 1),
      white
    ),
    url("${({ backgroundImage }) => backgroundImage}") no-repeat center;
  background-size: cover;
  color: ${({ theme }) => theme.color.black}; 
  
  .title {
    font-size: 26px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 15px;
  }
  
  .component {
    margin-bottom: 0;
    
    .ant-btn {
      margin-bottom: 0;
    }
  }
`
