import React from "react"
import PropTypes from "prop-types"
import Image from "images/Cards/cardstattoo.png"
import StyledCards from "./styles"

const Cards = ({ title, cards }) => (
  <StyledCards className="component">
    <div className="bg">
      <img src={Image} />
    </div>
    <div className="container">
      {title && <div className="title">{title}</div>}
      {cards}
    </div>
  </StyledCards>
)

Cards.propTypes = {
  title: PropTypes.string,
  cards: PropTypes.array,
}

export default Cards
