import React from "react"
import PropTypes from "prop-types"
// import PropTypes from "prop-types"
import MegaMenu from "components/MegaMenu"
import { MainMenuDesktopStyles } from "./styles"

const MainMenuDesktop = ({ logo, linkLogo, menu, components }) => {
  const megaMenus = menu.map(megaMenu => {
    const megaMenuTitle = megaMenu.entity.fieldTitle
    const megaMenuUrl = megaMenu.entity.fieldLinkMegaMenu?.entity.entityUrl.path
    const contentMegaMenu = megaMenu.entity.fieldMainMenuLinks && (
      <MegaMenu
        image={
          megaMenu.entity.fieldMediaImage?.entity.fieldMediaImage.megaMenu.url
        }
        menu={megaMenu.entity.fieldMainMenuLinks}
      />
    )

    return (
      <li>
        <a href={megaMenuUrl}>{megaMenuTitle}</a>
        {megaMenu.entity.fieldMainMenuLinks.length > 0 && contentMegaMenu}
      </li>
    )
  })

  return (
    <MainMenuDesktopStyles>
      {logo &&
        <div className="logo">
          <a href={linkLogo}>
            <img className="img-fluid" src={logo} alt="logo"/>
          </a>
        </div>
      }

      <ul>
        {megaMenus}
        <li>{components}</li>
      </ul>
    </MainMenuDesktopStyles>
  )
}

MainMenuDesktop.propTypes = {
  logo: PropTypes.string,
  menu: PropTypes.array,
  components: PropTypes.any,
}

export default MainMenuDesktop
