import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
// import components
import Accordions from "components/Accordions"
import { Collapse } from "antd"

const { Panel } = Collapse

const ParagraphAccordions = ({ content: { fieldAccordions } }) => {
  const accordions = fieldAccordions.map((fieldAccordion, keyValue) => (
    <Panel header={fieldAccordion.entity.fieldTitle} key={keyValue}>
      <div
        dangerouslySetInnerHTML={{
          __html: fieldAccordion.entity.fieldText?.value,
        }}
      />
    </Panel>
  ))

  return <Accordions listAccordions={accordions} />
}

export const fragmentAccordions = graphql`
  fragment ParagraphAccordions on Drupal_ParagraphAccordions {
    fieldAccordions {
      entity {
        ...ParagraphAccordion
      }
    }
  }
`

export const fragmentAccordion = graphql`
  fragment ParagraphAccordion on Drupal_ParagraphAccordion {
    fieldTitle
    fieldText {
      value
    }
  }
`

ParagraphAccordions.propTypes = {
  content: PropTypes.object,
}

export default ParagraphAccordions
