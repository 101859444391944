import styled from "styled-components"

export default styled.div`
  font-family: ${({ theme }) => theme.fontFamily.second};

  .ant-collapse {
    border: 1px solid #faeed4;
    width: 100%;

    .ant-collapse-header {
      font-size: 20px;
      padding-left: 16px !important;
      line-height: 24px;
      font-weight: 600;
    }

    .ant-collapse-item {
      border-bottom: 1px solid #faeed4;
    }

    .ant-collapse-content {
      border-top: 1px solid #faeed4;

      .ant-collapse-content-box {
        border-left: 4px solid ${({ theme }) => theme.primaryColor};
      }
    }
  }

  .ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-arrow {
    right: 15px;
    left: unset;

    svg {
      color: ${({ theme }) => theme.primaryColor};
    }
  }
`
