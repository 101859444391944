import React from "react"
import PropTypes from "prop-types"
import { getParagraph } from "utils/paragraphs"
import { graphql } from "gatsby"
import CustomThemeProvider from "configs/CustomThemeProvider"
// import components
import PageReference from "components/PageReference"
import Seo from "components/Seo"
import {
  StyledContent,
  StyledPageReference,
} from "components/layouts/Container/styles"
import HeaderCustomPage from "./HeaderCustomPage"
import FooterCustomPage from "./FooterCustomPage"

const CustomPage = ({
                      data: {
                        drupal: { nodeById },
                        site: { siteMetadata },
                      },
                      pageContext,
                    }) => {
  const pageReference = nodeById.fieldPageReference?.map((page, index) => {
    const indexPage = index + 1
    return (
      <>
        {page.entity && (
        <PageReference
          key={index}
          title={`${indexPage} - ${page.entity.title}`}
          image={
            page.entity.fieldMediaImageHeader?.entity.fieldMediaImage.pageReference.url
          }
          linkUrl={page.entity.entityUrl.path}
          content={page.entity.fieldPageReference}
        />
)}
      </>
    )
  })

  const content = nodeById.fieldContent?.map(getParagraph)
  const isHomepage = nodeById.fieldIsHomepage ? 'homepage' : 'page-content'

  return (
    <CustomThemeProvider
      color={nodeById.fieldSiteInternet?.entity.fieldCouleur?.color}
    >
      <Seo page={nodeById} metaData={siteMetadata} pageContext={pageContext} />
      <HeaderCustomPage nodeById={nodeById} />
      <StyledContent className={isHomepage}>{content}</StyledContent>
      {nodeById.fieldPageReference && (
        <StyledPageReference>{pageReference}</StyledPageReference>
      )}
      <FooterCustomPage nodeById={nodeById} />
    </CustomThemeProvider>
  )
}

export const query = graphql`
  query CustomPage($id: String!, $language: Drupal_LanguageId!) {
    site {
      siteMetadata {
        siteName
        postalCode
        email
        phoneNumber
        type
      }
    }
    drupal {
      nodeById(id: $id, language: $language) {
        ...NodeCustomPage
      }
      taxonomyTermQuery {
        entities {
          ...TaxonomyTermSiteInternet
        }
      }
    }
  }

  fragment NodeCustomPage on Drupal_NodeCustomPage {
    title
    fieldMediaImageHeader {
      entity {
        ...MediaImage
      }
    }
    fieldIsHomepage
    fieldMetaDescription
    fieldOgImage {
      entity {
        url
      }
    }
    entityUrl {
      path
    }
    entityTranslations {
      entityUrl {
        path
      }
      entityLanguage {
        id
      }
    }
    entityMetatags {
      key
      value
    }
    langcode {
      value
    }
    fieldContent {
      entity {
        __typename
        entityId
        ...ParagraphSlider
        ...ParagraphMainMenu
        ...ParagraphTitle
        ...ParagraphAccordions
        ...ParagraphCopyright
        ...ParagraphAlert
        ...ParagraphVideo
        ...ParagraphCallToAction
        ...ParagraphTextCallToAction
        ...ParagraphTextImage
        ...ParagraphImage
        ...ParagraphGoogleMap
        ...ParagraphCards
        ...ParagraphText
        ...ParagraphTabs
        ...ParagraphIcons
        ...ParagraphImagesLinks
        ...ParagraphLinks
        ...ParagraphIframe
        ...ParagraphSliderCarousel
        ...ParagraphBlockGatsby
      }
    }
    fieldSiteInternet {
      entity {
        ...TaxonomyTermSiteInternet
      }
    }
    fieldPageReference {
      entity {
        ...PageReference
      }
    }
  }

  fragment PageReference on Drupal_NodeCustomPage {
    title
    entityUrl {
      path
    }
    fieldMediaImageHeader {
      entity {
        ...MediaImage
      }
    }
    fieldPageReference {
      entity {
        title
        entityUrl {
          path
        }
      }
    }
  }

  fragment MediaImage on Drupal_MediaImage {
    entityId
    entityChanged
    fieldMediaImage {
      url
      card: derivative(style: CARD) {
        url
      }
      slider: derivative(style: SLIDER) {
        url
      }
      imageHeader: derivative(style: HEADERIMAGE) {
        url
      }
      pageReference: derivative(style: PAGEREF) {
        url
      }
      map: derivative(style: MAP) {
        url
      }
      siteInternet: derivative(style: SITEINTERNET) {
        url
      }
      carousel: derivative(style: CAROUSEL) {
        url
      }
      callToAction: derivative(style: CALLTOACTION) {
        url
      }
      imageLink: derivative(style: IMAGELINK) {
        url
      }
      textImage: derivative(style: TEXTIMAGE) {
        url
      }
      image: derivative(style: IMAGE) {
        url
      }
      landing: derivative(style: LANDING) {
        url
      }
      megaMenu: derivative(style: MEGAMENU) {
        url
      }
      
    }
  }

  fragment TaxonomyTermSiteInternet on Drupal_TaxonomyTermSiteInternet {
    entityId
    name
    fieldMenu {
      entity {
        ...BlockContentCustomBlock
      }
    }
    fieldFooter {
      entity {
        ...BlockContentCustomBlock
      }
    }
    fieldMediaImage {
      entity {
        ...MediaImage
      }
    }
    fieldCouleur {
      color
    }
    fieldLien {
      url {
        path
      }
    }
  }

  fragment BlockContentCustomBlock on Drupal_BlockContentCustomBlock {
    fieldContent {
      entity {
        __typename
        entityId
        ...ParagraphMainMenu
        ...ParagraphCopyright
        ...ParagraphIcons
        ...ParagraphLinks
        ...ParagraphBlockGatsby
      }
    }
  }
`

CustomPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default CustomPage
