import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
// import components
import Alert from "components/Alert"

const ParagraphAlertCustom = ({
  content: { fieldTitle, fieldDescription, fieldTypeAlert },
}) => (
  <Alert
    message={fieldTitle}
    description={fieldDescription}
    type={fieldTypeAlert}
  />
)

export const fragment = graphql`
  fragment ParagraphAlert on Drupal_ParagraphAlert {
    fieldTypeAlert
    fieldTitle
    fieldDescription
  }
`

ParagraphAlertCustom.propTypes = {
  content: PropTypes.object,
}

export default ParagraphAlertCustom
