import styled from "styled-components"

export default styled.div`
  position: relative;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center; 
  justify-content: center;
  
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: cover;
  min-height: 220px;
  
  > a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .content {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    
    .title { 
      position: relative;
      top: 0;
      color: ${({ theme }) => theme.color.white};
      font-size: 36px;
      line-height: 40px;
      text-align: center;
      text-transform: uppercase;
      font-family: ${({ theme }) => theme.fontFamily.main};
      font-weight: 900;
      transition: top 0.5s;
      text-shadow: 0 3px 6px rgba(0,0,0,0.8);
      
      &:after {
        width: 0;
        transition: width 0.7s;
        content: "";
      }
    }
  }
  
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  } 
  
  &:hover {
    .title {
      top: -15px;
      
      &:after {
        white-space: nowrap;
        position: absolute;
        content: "${({ subtitle }) => subtitle}";
        font-size: 18px;
        line-height: 24px;
        bottom: -25px;
        left: 0;
        right: 0;
        width: 100%;
        padding-top: ${({ theme }) => theme.primaryColor};
        border-top: 3px solid #FFF;
      }
    }
  }
`
