import React from "react"
import PropTypes from "prop-types"
import { Row } from "antd"
import StyledImagesLinks from "./styles"

const ImagesLinks = ({ links }) => (
  <StyledImagesLinks>
    <div className="container center">
      <Row
        gutter={[32, 32]}
        type="flex"
        align="middle"
        justify="center"
      >
        {links}
      </Row>
    </div>
  </StyledImagesLinks>
)

ImagesLinks.propTypes = {
  links: PropTypes.array,
}

export default ImagesLinks
