import React from "react"
import PropTypes from "prop-types"
import StyledText from "./styles"

const Text = ({ title, subtitle, htmlText, columnCount, isWrap }) => (
  <StyledText className="component" columnCount={columnCount}>
    <div className="container">
      {title && <div className="title">{title}</div>}
      {subtitle && <div className="subtitle">{subtitle}</div>}
      {htmlText &&
      <div className="text">
        <div
          dangerouslySetInnerHTML={{
            __html: htmlText,
          }}
        />
      </div>
      }
    </div>
  </StyledText>
)

Text.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  htmlText: PropTypes.string,
}

export default Text
