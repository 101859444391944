import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
// import components
import MainMenu from "components/MainMenu"

const ParagraphMainMenu = ({
  content: { fieldMediaLogo, fieldLinkLogo, fieldMegaMenus, components },
}) => (
  <MainMenu
    logo={
      fieldMediaLogo?.entity.fieldMediaImage.url
    }
    linkLogo={fieldLinkLogo?.entity.entityUrl.path}
    menu={fieldMegaMenus}
    components={components}
  />
)

export const fragment = graphql`
  fragment ParagraphMainMenu on Drupal_ParagraphMainMenu {
    fieldMediaLogo {
      entity {
        ...MediaImage
      }
    }
    
    fieldLinkLogo {
      entity {
        entityUrl {
          path
        }
      }
    }

    fieldMegaMenus {
      entity {
        ...MegaMenus
      }
    }
  }

  fragment MegaMenus on Drupal_ParagraphMainMenuMegaMenu {
    fieldTitle

    fieldLinkMegaMenu {
      entity {
        entityUrl {
          path
        }
      }
    }

    fieldMainMenuLinks {
      entity {
        ...MainMenuLinks
      }
    }

    fieldMediaImage {
      entity {
        ...MediaImage
      }
    }
  }

  fragment MainMenuLinks on Drupal_ParagraphMainMenuLinks {
    fieldTitle

    fieldMenuLink {
      entity {
        entityUrl {
          path
        }
      }
    }

    fieldSubMenuLinks {
      entity {
        ...SubMenuLinks
      }
    }
  }

  fragment SubMenuLinks on Drupal_ParagraphSubMainMenuLink {
    fieldTitle

    fieldMenuLink {
      entity {
        entityUrl {
          path
        }
      }
    }
  }
`

ParagraphMainMenu.propTypes = {
  content: PropTypes.object,
  components: PropTypes.any,
}

export default ParagraphMainMenu
