import React from "react"
import StyledIcon from "./styles"

const Icon = ({ url, icons }) => (
  <StyledIcon className="component">
    <div className="icons d-flex">
      <div className="icon">
        <a target="_blank" href={url}>{icons}</a>
      </div>
    </div>
  </StyledIcon>
  )

export default Icon
