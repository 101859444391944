import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import StyledLinks from "./styles"

const Links = ({ listLinks, title }) => {
  const links = listLinks.map((link, index) => (
    <Link key={index} to={link.url.path}>
      {link.title}
    </Link>
    ))

  return (
    <StyledLinks className="component">
      <div className="container">
        {title && <div className="title">{title}</div>}
        {links && <div className="links d-flex">{links}</div>}
      </div>
    </StyledLinks>
  )
}

Links.propTypes = {
  listLinks: PropTypes.array,
  title: PropTypes.string,
}

export default Links
