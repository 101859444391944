import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
// import components
import Title from "components/Title"

const ParagraphTitle = ({ content: { fieldTitle } }) => (
  <Title title={fieldTitle} />
)

export const fragment = graphql`
  fragment ParagraphTitle on Drupal_ParagraphTitle {
    fieldTitle
  }
`

ParagraphTitle.propTypes = {
  content: PropTypes.object,
}

export default ParagraphTitle
