import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "antd"
import StyledCallToAction from "./style"
import ButtonCustom from "../Button"

const CallToAction = ({
  backgroundImage,
  title,
  description,
  linkUri,
  linkText,
}) => (
  <StyledCallToAction className="component" backgroundImage={backgroundImage}>
    <Row type="flex" align="middle" justify="end">
      <Col lg={8} md={12} sm={16} xs={24}>
        {title && <div className="title">{title}</div>}
        {description && <p>{description}</p>}
        {linkText && linkUri && (
          <ButtonCustom
            link={linkUri}
            type="primary"
          >
            {linkText}
          </ButtonCustom>
        )}
      </Col>
    </Row>
  </StyledCallToAction>
)

CallToAction.propTypes = {
  backgroundImage: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  linkUri: PropTypes.string,
  linkText: PropTypes.string,
}

export default CallToAction
