import styled from "styled-components"

export default styled.div`
  padding: 30px 0;
  display: flex;
  justify-content: center;
  
  .title {
    font-family: ${({ theme }) => theme.fontFamily.main};
    font-size: 30px;
    line-height: 36px;
    font-weight: 600; 
    color: ${({ theme }) => theme.primaryColor};
    position: relative;
  }
`
