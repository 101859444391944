import styled from "styled-components"

export default styled.div`
  padding-top: 80px;
  position: relative;

  .slick-dots {
    bottom: 50px !important;

    button {
      height: 10px !important;
      &::before {
        display: none;
      }
    }
  }
`

export const SliderItemStyle = styled.div`
  z-index: 1;
  height: calc(80vh - 200px);

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: cover;

  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }

  .content {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index: 1;

    .title {
      font-family: Segoe UI Black, sans-serif;
      font-size: 120px;
      line-height: 130px;
      color: ${({ theme }) => theme.color.white};
      text-shadow: 0 5px 10px #000;
    }

    .subtitle {
      font-family: ${({ theme }) => theme.fontFamily.second};
      font-size: 50px;
      line-height: 55px;
      padding-bottom: 50px;
      color: white;
      text-shadow: 0 5px 10px #000;
    }
  }
  
  @media screen and (max-width: 1023px) {
    .content {
      .title {
        font-size: 80px;
        line-height: 85px;
      }
    }
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
  }
`
