import React from "react"
import { Tabs } from "antd"
// import images
import TabsStyles from "./styles"

const CustomTabs = ({ position, children }) => (
  <TabsStyles className="component">
    <div className={`container card-container-${position}`}>
      <Tabs type="card" tabPosition={position}>
        {children}
      </Tabs>
    </div>
  </TabsStyles>
  )

export default CustomTabs
