import styled from "styled-components"

export default styled.div`
   
  .bgcolor {
    background-color: ${({ theme }) => theme.primaryColor};
    display: flex;
    justify-content: center;
  }
  
  .image-bg {
    background-image: url(${({ imageUrl }) => imageUrl});
    background-size: cover;
    height: 100%;
    min-height: 360px;
  }
  
  .wrapper {
    padding: 25px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: ${({ theme }) => theme.color.black};
  
    .title {
      font-family: ${({ theme }) => theme.fontFamily.main};
      color: ${({ theme }) => theme.color.white};
      font-weight: 800;
      font-size: 36px;
      line-height: 40px;
      margin-bottom: 10px;
    }
  
    .text {
      color: ${({ theme }) => theme.color.white};
      font-size: 18px;
      line-height: 24px;
      font-family: ${({ theme }) => theme.fontFamily.second};
      margin-bottom: 20px;
    }
  
    .ant-btn {
      margin-bottom: 0;
    }
  }
`
