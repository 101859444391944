import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
// import components
import TextCallToAction from "components/TextCallToAction"

const ParagraphTextCallToAction = ({
  content: { fieldTitle, fieldDescription, fieldLink },
}) => (
  <TextCallToAction
    title={fieldTitle}
    description={fieldDescription}
    linkUri={fieldLink.url.path}
    linkText={fieldLink.title}
  />
)

export const fragment = graphql`
  fragment ParagraphTextCallToAction on Drupal_ParagraphTextCallToAction {
    fieldTitle
    fieldDescription
    fieldLink {
      title
      url {
        path
      }
    }
  }
`

ParagraphTextCallToAction.propTypes = {
  content: PropTypes.object,
}

export default ParagraphTextCallToAction
