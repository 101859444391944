import styled from "styled-components"

export default styled.div`
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily.second};

  .container {
    flex-direction: column;
  }

  .title {
    font-weight: 600;
    font-family: ${({ theme }) => theme.fontFamily.main};
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.primaryColor};
  }

  .subtitle {
    font-size: 24px;
    line-height: 28px;
    color: ${({ theme }) => theme.secondaryColor};
    font-weight: 600;
    margin-bottom: 20px;
  }

  .text {
    column-count: ${({ columnCount }) => columnCount};
    column-gap: 45px;
    
    p {
      font-size: 18px;
      line-height: 22px;
      color: black;
    }
    
    strong {
      font-weight: 700;
    }
  }

  @media screen and (max-width: 650px) {
    .text {
      column-count: 1;
    }
  }
`
