import React from "react"
import ParagraphAccordions from "paragraphs/paragraphAccordions"
import ParagraphTitle from "paragraphs/paragraphTitle"
import ParagraphMainMenu from "paragraphs/paragraphMainMenu"
import ParagraphCopyright from "paragraphs/paragraphCopyright"
import ParagraphText from "paragraphs/paragraphText"
import ParagraphTextImage from "paragraphs/paragraphTextImage"
import ParagraphImage from "paragraphs/paragraphImage"
import ParagraphAlert from "paragraphs/paragraphAlert"
import ParagraphSlider from "paragraphs/paragraphSlider"
import ParagraphVideo from "paragraphs/paragraphVideo"
import ParagraphCallToAction from "paragraphs/paragraphCallToAction"
import ParagraphTextCallToAction from "paragraphs/paragraphTextCallToAction"
import ParagraphGoogleMap from "paragraphs/paragraphMap"
import ParagraphCards from "paragraphs/paragraphCards"
import ParagraphIcons from "paragraphs/paragraphIcons"
import ParagraphImagesLinks from "paragraphs/paragraphImageLinks"
import ParagraphLinks from "paragraphs/paragraphLinks"
import ParagraphTabs from "paragraphs/paragraphTabs"
import ParagraphIframe from "paragraphs/paragraphIframe"
import ParagraphSliderCarousel from "paragraphs/paragraphSliderCarousel"
import ParagraphBlockGatsby from "paragraphs/paragraphBlockGatsby"

const components = {
  Drupal_ParagraphTitle: ParagraphTitle,
  Drupal_ParagraphMainMenu: ParagraphMainMenu,
  Drupal_ParagraphText: ParagraphText,
  Drupal_ParagraphAccordions: ParagraphAccordions,
  Drupal_ParagraphAlert: ParagraphAlert,
  Drupal_ParagraphSlider: ParagraphSlider,
  Drupal_ParagraphCopyright: ParagraphCopyright,
  Drupal_ParagraphTextImage: ParagraphTextImage,
  Drupal_ParagraphImage: ParagraphImage,
  Drupal_ParagraphVideo: ParagraphVideo,
  Drupal_ParagraphCallToAction: ParagraphCallToAction,
  Drupal_ParagraphTextCallToAction: ParagraphTextCallToAction,
  Drupal_ParagraphGoogleMap: ParagraphGoogleMap,
  Drupal_ParagraphCards: ParagraphCards,
  Drupal_ParagraphIcons: ParagraphIcons,
  Drupal_ParagraphImagesLinks: ParagraphImagesLinks,
  Drupal_ParagraphLinks: ParagraphLinks,
  Drupal_ParagraphTabs: ParagraphTabs,
  Drupal_ParagraphIframe: ParagraphIframe,
  Drupal_ParagraphSliderCarrousel: ParagraphSliderCarousel,
  Drupal_ParagraphBlockGatsby: ParagraphBlockGatsby,
}

export const getParagraph = data => {
  if (data.entity !== null) {
    const { entity } = data
    const typeParagraph = entity.__typename

    console.log(typeParagraph)

    if (components.hasOwnProperty(typeParagraph)) {
      const ParagraphComponent = components[typeParagraph]
      return (
        <ParagraphComponent
          components={entity.components}
          key={entity.entityId}
          content={entity}
        />
      )
    }

    return null
  }

  return null
}
