import React, { useState } from "react"
import PropTypes from "prop-types"
// import PropTypes from "prop-types"
import SidebarMultilevel from "components/SidebarMultilevel"
import { Icon } from "antd"
import { MainMenuMobileStyles } from "./styles"
import "components/SidebarMultilevel/Sidebar.css"

const MainMenuMobile = ({ logo, menu, components }) => {
  const [isOpen, setIsOpen] = useState(false)
  const menuOptions = menu.map(megaMenu => {
    const option = {
      hideBorder: true,
      content: [
        {
          id: megaMenu.entity.fieldTitle,
          name: megaMenu.entity.fieldTitle,
          to:
            megaMenu?.entity?.fieldLinkMegaMenu?.entity?.entityUrl?.path || "#",
        },
      ],
    }
    if (megaMenu?.entity?.fieldMainMenuLinks?.length) {
      option.content[0].children = megaMenu?.entity?.fieldMainMenuLinks?.map(
        menuLink => {
          const subMenu = {
            hideBorder: true,
            content: [
              {
                id: menuLink.entity.fieldTitle,
                name: menuLink.entity.fieldTitle,
                to:
                  menuLink?.entity?.fieldMenuLink?.entity?.entityUrl?.path ||
                  "#",
              },
            ],
          }
          if (menuLink?.entity?.fieldSubMenuLinks?.length) {
            subMenu.content[0].children = menuLink?.entity?.fieldSubMenuLinks?.map(
              subMenuLink => {
                const subSubMenu = {
                  hideBorder: true,
                  content: [
                    {
                      id: subMenuLink.entity.fieldTitle,
                      name: subMenuLink.entity.fieldTitle,
                      to:
                        subMenuLink?.entity?.fieldMenuLink?.entity?.entityUrl
                          ?.path || "#",
                    },
                  ],
                }
                return subSubMenu
              }
            )
          }
          return subMenu
        }
      )
    }
    return option
  })

  const handleSidebarToggle = status => {
    setIsOpen(status)
  }

  const openSibebar = () => {
    setIsOpen(true)
  }

  return (
    <>
      <MainMenuMobileStyles className="component">
        <div className="burger-icon">
          <Icon
            type="menu"
            onClick={openSibebar}
            style={{ fontSize: 25, color: "#fff" }}
          />
        </div>
        <div className="logo">
          <img className="img-fluid" src={logo} alt="" />
        </div>

        <div className="right-content">{components}</div>
      </MainMenuMobileStyles>
      <SidebarMultilevel
        open={isOpen}
        onToggle={handleSidebarToggle}
        options={menuOptions}
        header={
          <div className="sidebar-header">
            <img className="img-fluid" src={logo} alt="" />
          </div>
        }
      />
    </>
  )
}

MainMenuMobile.propTypes = {
  logo: PropTypes.string,
  menu: PropTypes.array,
  components: PropTypes.any,
}

export default MainMenuMobile
