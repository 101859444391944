import styled from "styled-components"
import BackgroundImage from "images/TextImage/background-image.png"

export default styled.div`
  font-family: ${({ theme }) => theme.fontFamily.second};
  padding: 40px 0;
  
  .content {
    .title {
      font-size: 30px;
      line-height: 36px;
      font-weight: 600; 
      font-family: ${({ theme }) => theme.fontFamily.main};
      color: ${({ theme }) => theme.primaryColor};
      margin-bottom: 20px;
    }

    .description {
      font-size: 20px;
      line-height: 24px;
      max-width: 100%;
    }
    
    .component {
      margin-bottom: 0 !important;
      
      .ant-btn {
        margin-bottom: 0 !important;
      }
    }
  }
  
  .background-image {
    background-image: url(${BackgroundImage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .left {
    flex-direction: row-reverse;
  }
  
  .right {
    flex-direction: row;
  }
`
