import React from "react"
import PropTypes from "prop-types"
// import PropTypes from "prop-types"
import StyledMainMenu from "./styles"
import MainMenuDesktop from "./MainMenuDesktop"
import MainMenuMobile from "./MainMenuMobile"

const MainMenu = ({ logo, linkLogo, menu, components }) => {
  return (
    <StyledMainMenu className="component">
      <div className="menu-desktop">
        <MainMenuDesktop logo={logo} linkLogo={linkLogo} menu={menu} components={components}/>
      </div>

      <div className="menu-mobile">
        <MainMenuMobile logo={logo} linkLogo={linkLogo} menu={menu} components={components}/>
      </div>
    </StyledMainMenu>
  )
}

MainMenu.propTypes = {
  logo: PropTypes.string,
  menu: PropTypes.array,
  components: PropTypes.any,
}

export default MainMenu
