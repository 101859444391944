import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"
import theme from "configs/theme"

const CustomThemeProvider = ({ color = "#000", children }) => {
  theme.primaryColor = color
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}

CustomThemeProvider.propTypes = {
  color: PropTypes.string,
  children: PropTypes.any,
}

export default CustomThemeProvider
