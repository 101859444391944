import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
// import components
import SectionVideo from "components/SectionVideo"

const ParagraphSectionVideo = ({ content : {fieldTitle, fieldDescription, fieldLink, fieldUrlVideo} }) => (
  <SectionVideo
    title={fieldTitle}
    description={fieldDescription}
    linkUrl={fieldLink?.url.path}
    linkText={fieldLink?.title}
    videoUrl={fieldUrlVideo?.uri}
  />
)

export const fragment = graphql`
  fragment ParagraphVideo on Drupal_ParagraphVideo {
    fieldTitle
    fieldDescription
    fieldUrlVideo {
      uri
    }
    fieldLink {
      title
      url {
        path
      }
    }
  }
`

ParagraphSectionVideo.propTypes = {
  content: PropTypes.object,
}

export default ParagraphSectionVideo
