import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
// import components
import Image from "components/Image"

const ParagraphImage = ({
  content: {
    fieldMediaImage,
  },
  }) => (
    <Image
      image={fieldMediaImage?.entity.fieldMediaImage.image.url}
    />
)

export const fragment = graphql`
  fragment ParagraphImage on Drupal_ParagraphImage {
    fieldMediaImage {
      entity {
        ...MediaImage
      }
    }
  }
`

ParagraphImage.propTypes = {
  content: PropTypes.object,
}

export default ParagraphImage
