import styled from "styled-components"
import TattooVertical from "images/Tabs/TabsVerticalBar.png"
import TattooHorizontal from "images/Tabs/TabsHorizontalBar.png"

export default styled.div`
  padding: 50px 0;

  .card-container-normal {
    & > .ant-tabs-normal {
      & > .ant-tabs-bar {
        .ant-tabs-nav {
          width: 100%;
          .ant-tabs-tab {
            padding: 15px 30px;
          }
        }
      }
    }

    & > .ant-tabs-card {
      width: 100%;

      .ant-tabs-card-bar {
        .ant-tabs-nav-container {
          height: auto;
        }
      }

      & > .ant-tabs-content {
        margin-top: -16px;

        & > .ant-tabs-tabpane {
          background: #f5f5f5;
          padding: 20px 20px 80px 20px;
          position: relative;

          &:after {
            height: 85px;
            width: 100%;
            position: absolute;
            content: "";
            bottom: 20px;
            left: 0;
            right: 0;
            background: url(${TattooHorizontal});
          }
        }
      }

      & > .ant-tabs-bar {
        border-color: #f5f5f5;

        .ant-tabs-nav {
          width: 100%;
        }

        .ant-tabs-tab {
          border-color: transparent;
          background: transparent;
          font-size: 20px;
          line-height: 24px;
          padding: 10px 30px;
          height: auto;
          font-weight: 700;
          color: #c3c3c3;
          margin-bottom: 0 !important;
        }

        .ant-tabs-tab-active {
          border-color: #f5f5f5;
          background: #f5f5f5;
          color: ${({ theme }) => theme.primaryColor};
          border-top: ${({ theme }) => theme.primaryColor} solid 4px;
          border-top-left-radius: none;
          border-top-right-radius: none;
        }
      }
    }
  }
`
