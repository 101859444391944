import styled from "styled-components"

export default styled.div`
  .newsletter-section {
    text-align: center;

    .title {
      font-size: 50px;
      line-height: 56px;
      font-family: ${({ theme }) => theme.fontFamily.bigTitle};
      margin-bottom: 20px;
    }

    .description {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 20px;
      font-family: ${({ theme }) => theme.fontFamily.second};
    }

    .ant-form-item {
      margin: 0;
      
      button {
        height: 50px; 
        border-radius: 0;
      }
    }
  }
`
