import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
// import components
import TextImage from "components/TextImage"

const ParagraphTextImage = ({
  content: {
    fieldTitle,
    fieldTextAlign,
    fieldMediaImage,
    fieldBackgroundImage,
    fieldText,
    fieldLink,
  },
  }) => (
    <TextImage
      title={fieldTitle}
      textAlign={fieldTextAlign}
      image={fieldMediaImage?.entity.fieldMediaImage.textImage.url}
      isBackgroundImage={fieldBackgroundImage}
      text={fieldText.value}
      linkUri={fieldLink?.uri}
      linkText={fieldLink?.title}
    />
)

export const fragment = graphql`
  fragment ParagraphTextImage on Drupal_ParagraphTextImage {
    fieldTitle
    fieldText {
      value
    }
    fieldLink {
      title
      uri
    }
    fieldBackgroundImage
    fieldTextAlign
    fieldMediaImage {
      entity {
        ...MediaImage
      }
    }
  }
`

ParagraphTextImage.propTypes = {
  content: PropTypes.object,
}

export default ParagraphTextImage
