import React from "react"
// import PropTypes from "prop-types"
import FR from "images/LanguageSwitcher/FR.png"
import EN from "images/LanguageSwitcher/EN.png"
import { Select } from "antd"
import StyledLanguageSwitcher from "./styles"

// import components
const { Option } = Select

const LanguageSwitcher = ({ currentURL, translations }) => {
  const onChange = event => {
    const base_url = window.location.origin
    const redirection = base_url + event
    window.location.replace(redirection)
  }

  const options = translations.map((translation, key) => {
    const lang = () => {
      if (translation.entityLanguage.id === "fr") {
        return (
          <>
            <img src={FR} alt="logo-fr" />
            {' '}
            FR
          </>
        )
      }

      return (
        <>
          <img src={EN} alt="logo-en" />
          {' '}
          EN
        </>
      )
    }

    return (
      <Option key={key} value={translation.entityUrl.path}>
        {lang()}
      </Option>
    )
  })

  return (
    <StyledLanguageSwitcher>
      <Select defaultValue={currentURL} onChange={onChange}>
        {options}
      </Select>
    </StyledLanguageSwitcher>
  )
}

export default LanguageSwitcher
