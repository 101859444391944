import React from "react"
import PropTypes from "prop-types"
// import components
import { graphql } from "gatsby"
import { Col } from "antd"
import ImageLink from "components/ImageLink"
import ImagesLinks from "components/ImagesLinks"

const ParagraphImageLinks = ({ content: { fieldImagesLinks } }) => {
  const imageLinks = fieldImagesLinks.map(result => (
    <Col lg={8} md={24} sm={24} xs={24} key={result.entity.entityId}>
      <ImageLink
        title={result.entity.fieldTitle}
        subtitle={result.entity.fieldSubtitle}
        imageUrl={result.entity.fieldMediaImage.entity.fieldMediaImage.imageLink.url}
        linkUrl={result.entity.fieldLink.url.path}
      />
    </Col>
  ))

  return <ImagesLinks links={imageLinks} />
}

export const fragment = graphql`
  fragment ParagraphImagesLinks on Drupal_ParagraphImagesLinks {
    fieldImagesLinks {
      entity {
        ...ParagraphImageLink
      }
    }
  }

  fragment ParagraphImageLink on Drupal_ParagraphImageLink {
    entityId
    fieldTitle
    fieldSubtitle
    fieldLink {
      url {
        path
      }
    }
    fieldMediaImage {
      entity {
        ...MediaImage
      }
    }
  }
`

ParagraphImageLinks.propTypes = {
  content: PropTypes.object,
}

export default ParagraphImageLinks
