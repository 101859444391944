import React from "react"
import PropTypes from "prop-types"
import StyledTitle from "./styles"

const Title = ({ title }) => (
  <StyledTitle>
    {title && <div className="title">{title}</div>}
  </StyledTitle>
  )

Title.propTypes = {
  title: PropTypes.string,
}

export default Title
