import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Tabs } from "antd"
import { getParagraph } from "utils/paragraphs"
// import components
import CustomTabs from "components/CustomTabs"

const { TabPane } = Tabs

const ParagraphTabs = ({ content: { fieldTabpanes } }) => {
  const tabpanes = fieldTabpanes.map((tabpane, index) => {
    const tabpane_content = tabpane.entity.fieldContent.map(getParagraph)
    return (
      <TabPane tab={tabpane.entity.fieldTitle} key={index}>
        {tabpane_content}
      </TabPane>
    )
  })

  return (
    <CustomTabs position="normal">
      {tabpanes}
    </CustomTabs>
  )
}

export const fragment = graphql`
  fragment ParagraphTabs on Drupal_ParagraphTabs {
    fieldTabpanes {
      entity {
        __typename
        entityId
        ...ParagraphTabPane
      }
    }
  }

  fragment ParagraphTabPane on Drupal_ParagraphTabpane {
    fieldTitle
    fieldContent {
      entity {
        __typename
        entityId
        ...ParagraphAccordions
        ...ParagraphAlert
        ...ParagraphTextImage
        ...ParagraphText
        ...ParagraphTitle
      }
    }
  }
`

ParagraphTabs.propTypes = {
  content: PropTypes.object,
}

export default ParagraphTabs
