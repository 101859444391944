import styled from "styled-components"
import Bar from "images/HeaderImage/bar.png"

export default styled.div`
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; 
  overflow: hidden;
  
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: cover;
  
  min-height: 200px;
  
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }
  
  .content {
    position: absolute;
    display: flex;
    z-index: 10;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    
    .title {
      text-align: center;
      h1 {
        font-family: ${({ theme }) => theme.fontFamily.bigTitle};
        font-size: 60px;
        line-height: 64px;
        color: white;
        margin-bottom: 0;
      }
    }
  }
  
  @media screen and (max-width: 1023px) {
    .content {
      .title {
        h1 {
          font-size: 45px;
          line-height: 50px;
        }
      }
    }
  }
  
  .styledBar {
    background: url(${Bar}) no-repeat center;
    content: "";
    position: absolute;
    left: 20px;
    top: 0;
    height: 200px;
    width: 17px;
    z-index: 1;
  }
`
