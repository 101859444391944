import React from "react"
import PropTypes from "prop-types"
import { Col, Row } from "antd"
import Button from "components/Button"
import StyledSectionVideo from "./styles"

const SectionVideo = ({ title, description, linkUrl, linkText, videoUrl }) => {
  let customUrl = videoUrl

  if (videoUrl.includes("watch?v=")) {
    customUrl = videoUrl.replace("watch?v=", "embed/")
  }

  return (
    <StyledSectionVideo>
      <div className="container">
        <Row gutter={[50]} type="flex" align="middle">
          <Col lg={8} md={24} xs={24}>
            {title && <div className="title uppercase">{title}</div>}
            {description && <div className="content">{description}</div>}
            {linkUrl && linkText && (
              <Button link={linkUrl} type="primary rounded">
                {linkText}
              </Button>
            )}
          </Col>
          <Col lg={16} md={24} xs={24}>
            <div className="video">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe className="embed-responsive-item" src={customUrl} />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </StyledSectionVideo>
  )
}

SectionVideo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

export default SectionVideo
