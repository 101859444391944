import React from "react"
import Cards from "components/Cards";
import Card from "components/Cards/Card"
import { useStaticQuery, graphql } from "gatsby"
import { Col, Row } from "antd"

const ViewsListActuTapuFR = () => {
  const allListActuTapuFR = useStaticQuery(
    graphql`
    query {
      drupal {
        nodeQuery(limit: 1000, filter: {conditions: [{operator: EQUAL, field: "langcode", value: ["fr"]}, {operator: EQUAL, field: "type", value: ["article"]}, {operator: EQUAL, field: "field_site_internet", value: ["1"]}]}) {
          entities {
            entityTranslations {
              entityLabel
              entityId
              entityLanguage {
                id
              }
              ...NodeArticle
            }
          }
        }
      }
    }
  `,
  )

  const actus = allListActuTapuFR.drupal.nodeQuery.entities.map(entity => {
    const cards = entity.entityTranslations.map(translation => {
      if (translation.entityLanguage.id == "fr") {
        return (
          <Col lg={8} md={12} sm={24} xs={24} key={translation.entityId}>
            <Card title={translation.title} subtitle={translation.fieldDescription} imageUrl={translation.fieldMediaImage?.entity.fieldMediaImage.card.url} linkUrl={translation.entityUrl.path} linkText="En savoir plus"/>
          </Col>
        )
      }
    })

    return cards;
  })

  const rowCards = (
    <Row type="flex" gutter={[16, 10]}>
      {actus}
    </Row>
  )

  return <Cards cards={rowCards} />
}

export default ViewsListActuTapuFR
