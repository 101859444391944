import React from "react"
import { SliderItemStyle } from "./styles"

const SliderItem = ({ title, subtitle, image }) => (
  <SliderItemStyle imageUrl={image}>
    <div className="content">
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </div>
    <div className="overlay" />
  </SliderItemStyle>
)

export default SliderItem
