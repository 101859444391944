import React from "react"
import PropTypes from "prop-types"
import StyledImageLink from "./styles"

const ImageLink = ({ title, subtitle, imageUrl, linkUrl }) => (
  <StyledImageLink subtitle={subtitle} imageUrl={imageUrl}>
    <div className="content">
      {title && <div className="title">{title}</div>}
    </div>
    {linkUrl && <a href={linkUrl} />}
  </StyledImageLink>
)

ImageLink.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  imageUrl: PropTypes.string,
  linkUrl: PropTypes.string,
}

export default ImageLink
