import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
// import components
import SliderCarousel from "components/SliderCarousel"
import Img from "gatsby-image"

const ParagraphSliderCarousel = ({
   content: { fieldTitle, fieldDescription, fieldMediaImages },
 }) => {
  const images = fieldMediaImages.map(image => (
    <img key={image.entity.entityId} src={image.entity.fieldMediaImage.carousel.url}/>
  ))

  return (
    <SliderCarousel
      title={fieldTitle}
      description={fieldDescription}
      images={images}
    />
  )
}

export const fragment = graphql`
  fragment ParagraphSliderCarousel on Drupal_ParagraphSliderCarrousel {
    fieldTitle
    fieldDescription
    fieldMediaImages {
      entity {
        ...MediaImage
      }
    }
  } 
`

ParagraphSliderCarousel.propTypes = {
  content: PropTypes.object,
}

export default ParagraphSliderCarousel
