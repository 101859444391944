import React from "react"
import PropTypes from "prop-types"
import StyledTextCallToAction, { Wrapper } from "./styles"
import ButtonCustom from "../Button"

const TextCallToAction = ({ title, description, linkUri, linkText }) => (
  <Wrapper>
    <StyledTextCallToAction>
      {title && <div className="title">{title}</div>}
      {description && <div className="content">{description}</div>}
      {linkText && (
      <ButtonCustom
        link={linkUri}
        type="primary"
      >
        {linkText}
      </ButtonCustom>
        )}
    </StyledTextCallToAction>
  </Wrapper>
  )

TextCallToAction.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  linkUri: PropTypes.string,
  linkText: PropTypes.string,
}

export default TextCallToAction
