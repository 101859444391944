import React from "react"
import PropTypes from "prop-types"
import { Row } from "antd"
import StyledIcons from "./styles"

const Icons = ({ title, icons }) => (
  <StyledIcons className="component">
    <div className="container">
      <div className="title">{title}</div>
      {icons && (
        <Row type="flex" align="middle">
          {icons}
        </Row>
      )}
    </div>
  </StyledIcons>
)

Icons.propTypes = {
  title: PropTypes.string,
  icons: PropTypes.array,
}

export default Icons
