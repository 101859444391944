import styled from "styled-components"

export default styled.div`
  .ant-btn {
    margin-bottom: 30px;
    text-shadow: none;
    padding: 8px 25px;
    height: auto;
    font-size: 18px;
    font-weight: 600;
  }

  .rounded {
    border-radius: 30px;
  }

  .ant-btn-primary {
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.primaryColor};
    border: 2px solid ${({ theme }) => theme.primaryColor};

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.primaryColor};
      background-color: ${({ theme }) => theme.color.white};
      border: 2px solid ${({ theme }) => theme.primaryColor};
    }
  }

  .ant-btn-secondary {
    color: ${({ theme }) => theme.primaryColor};
    background-color: ${({ theme }) => theme.color.white};
    border: 2px solid ${({ theme }) => theme.primaryColor};

    &:hover {
      color: ${({ theme }) => theme.color.white};
      background-color: ${({ theme }) => theme.primaryColor};
      border: 2px solid ${({ theme }) => theme.color.white};
    }
  }
`
