import React, { useState } from "react"
import PropTypes from "prop-types"
import { Form, Input, notification } from "antd"
import ButtonCustom from "components/Button"
import StyledNewsletter from "./styles"

const Newsletter = ({ form }) => {
  const [isSubmiting, setIsSubmiting] = useState(false)
  const { getFieldDecorator } = form

  const handleSubmit = e => {
    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err) {
        setIsSubmiting(false)

        form.resetFields()
        notification.success({
          message: "Vous avez bien été inscrit à la newsletter",
        })
      }
    })
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  }

  return (
    <StyledNewsletter>
      <div className="container">
        <div className="newsletter-section">
          <div className="title">Recevez notre Newsletter.</div>
          <div className="description">
            Pour ne rater aucune de nos informations, restez connecté en vous abonnant à notre newsletter !
          </div>
          <Form name="newsletter" layout="inline">
            <Form.Item>
              {getFieldDecorator("email", {
                rules: [
                  {
                    required: true,
                    type: "email",
                    message: "Adresse email requise",
                  },
                ],
                initialValue: "",
              })(
                <Input
                  className="act-input"
                  type="Input.Email"
                  placeholder="Adresse email"
                />,
              )}
            </Form.Item>
            <Form.Item>
              <ButtonCustom
                type="primary"
                htmlType="submit"
                loading={isSubmiting}
                onclick={handleSubmit}
              >
                S’inscrire
              </ButtonCustom>
            </Form.Item>
          </Form>
        </div>
      </div>
    </StyledNewsletter>
  )
}

Newsletter.propTypes = {
  form: PropTypes.object,
}

export default Form.create({ name: "newsletter" })(Newsletter)
