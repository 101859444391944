import React from "react"
import StyledIframe from "./styles"

const Iframe = ({ title, width, height, url }) => (
  <StyledIframe className="component">
    <div className="container">
      <iframe
        title={title}
        width={width}
        height={height}
        frameBorder="0"
        src={url}
      />
    </div>
  </StyledIframe>
  )

export default Iframe
