import styled from "styled-components"

export default styled.div`
  background-color: ${({ theme }) => theme.primaryColor};
  padding: 60px 0;
  width: 100%;
  
  .container {
    padding: 0;
  }
  .ant-row-flex {
    width: 100%;
  }
`
