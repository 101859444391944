import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { Row, Col } from "antd"
import StyledImage from "./styles"

const Image = ({
 image,
}) => (
  <StyledImage>
    <div className="container">
      <Row type="flex" align="middle" justify="center" gutter={[32,32]}>
        <Col xs={24}>
          {image && <img className="img-fluid" src={image} />}
        </Col>
      </Row>
    </div>
  </StyledImage>
)

Image.propTypes = {
  textAlign: PropTypes.string,
  imgUrl: PropTypes.string,
  isBackgroundImg: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
}

export default Image
