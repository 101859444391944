import styled from "styled-components"

export default styled.div`
  .ant-alert {
    .ant-alert-message {
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
    }
    
    .ant-alert-description {
      font-size: 20px;
      line-height: 24px;
    }
  }
`
