import styled from "styled-components"

export const MainMenuMobileStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 25px;

  .burger-icon,
  .right-content {
    width: 90px;
    display: flex;
  }

  .right-content {
    text-align: right;
    flex-direction: column;
  }
`

export default styled.div`
  .menu-desktop {
    display: block;
  }
  
  .menu-mobile {
    display: none;
  }

  @media all and (max-width: 991px) {
    .menu-mobile {
      display: block;
    }
    .menu-desktop {
      display: none;
    }
  }
`

export const MainMenuDesktopStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;

  .logo {
    padding-left: 10px;
  }

  > ul {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    height: 100%;

    > li {
      height: 100%;
      display: flex;
      align-items: center;
      list-style: none;

      &:hover {
        .mega-menu {
          display: block;
        }
      }

      > a {
        color: ${({ theme }) => theme.color.white};
        font-size: 20px;
        line-height: 26px;
        position: relative;
      }
    }
  }
`
