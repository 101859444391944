import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { Row, Col } from "antd"
import StyledTextImage from "./styles"
import ButtonCustom from "../Button"

const TextImage = ({
 title,
 textAlign,
 image,
 isBackgroundImage,
 text,
 linkUri,
 linkText,
}) => {

  const getContent = () => {
    if(textAlign == 'right') {
      return (
        <>
          <Col lg={12} md={12} sm={24} xs={24}>
            {image && <img className="img-fluid" src={image} />}
          </Col>

          <Col lg={12} md={12} sm={24} xs={24}>
            <div
              className={`content ${isBackgroundImage ? "background-image" : ""}`}
            >
              {title && <div className="title">{title}</div>}
              {text && (
                <div className="description">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: text,
                    }}
                  />
                </div>
              )}
              {linkUri && linkText && (
                <ButtonCustom
                  size="large"
                  shape="rectangle"
                  link={linkUri}
                  uppercase=""
                  type="primary"
                >
                  {linkText}
                </ButtonCustom>
              )}
            </div>
          </Col>
        </>
      )
    }

    return (
      <>
        <Col lg={12} md={12} sm={24} xs={24}>
          <div
            className={`content ${isBackgroundImage ? "background-image" : ""}`}
          >
            {title && <div className="title">{title}</div>}
            {text && (
              <div className="description">
                <div
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                />
              </div>
            )}
            {linkUri && linkText && (
              <ButtonCustom
                size="large"
                shape="rectangle"
                link={linkUri}
                uppercase=""
                type="primary"
              >
                {linkText}
              </ButtonCustom>
            )}
          </div>
        </Col>

        <Col lg={12} md={12} sm={24} xs={24}>
          {image && <img className="img-fluid" src={image} />}
        </Col>
      </>
    )
  }

  return (
    <StyledTextImage>
      <div className="container">
        <Row type="flex" align="middle" justify="center" gutter={[32,32]}>
          {getContent()}
        </Row>
      </div>
    </StyledTextImage>
  )
}

TextImage.propTypes = {
  textAlign: PropTypes.string,
  imgUrl: PropTypes.string,
  isBackgroundImg: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
}

export default TextImage
