import styled from "styled-components"
import Tattoo from "images/SectionVideo/bg-tattoo.png"

export default styled.div` 
  background: url(${Tattoo}) center no-repeat;
  background-size: cover;
  padding: 100px 0;

  .title {
    font-weight: 800;
    font-family: ${({ theme }) => theme.fontFamily.main};
    font-size: 36px;
    line-height: 40px;
    color: ${({ theme }) => theme.primaryColor};
  }

  .content {
    padding: 25px 0;
    font-family: ${({ theme }) => theme.fontFamily.second};
    font-size: 18px;
    line-height: 24px;
    overflow-wrap: break-word;
  }

  .video {
    .embed-responsive { 
      min-height: 400px;
    }
  }

  iframe {
    width: 100%;
    box-shadow: 0 0 24px 6px rgba(0, 0, 0, 0.43);
  }
`

