import React from "react"
import PropTypes from "prop-types"
import { Alert } from "antd"
import StyledAlert from "./styles"

const AlertCustom = ({ message, description, type }) => (
  <StyledAlert className="component">
    <div className="container">
      <Alert
        message={message}
        description={description}
        type={type}
        showIcon
        closable
      />
    </div>
  </StyledAlert>
  )

AlertCustom.propTypes = {
  message: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
}

export default AlertCustom
