import styled from "styled-components"

export default styled.div`
  padding: 50px;
  background-color: #f5f5f5;
  font-family: ${({ theme }) => theme.fontFamily.second};
  position: relative;
  z-index: 1;
  overflow: hidden;

  .container {
    flex-direction: column;
  }

  .bg {
    img {
      position: absolute;
      right: 0;
      top: -10%;
      z-index: -1;
    }
  }
  
  .title {
    padding-bottom: 20px;
    font-size: 30px;
    line-height: 36px;
    color: ${({ theme }) => theme.color.black};
    font-weight: 600;
  }
  
  .card {
    max-width: 500px;
    width: 100%;
    height: 100%;
    position: relative;
    box-shadow: 0 1px 20px rgba(0,0,0,0.3);
    
    > a {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
    
    .content {
      padding: 15px;
      min-height: 156px; 
      display: flex;
      background-color: white;
      flex-direction: column;
      
      .title {
        margin-bottom: 10px;
        font-size: 20px;
        line-height: 24px;
        color: ${({ theme }) => theme.color.black};
        font-weight: 600; 
      }
    
      .subtitle {
        font-size: 20px;
        line-height: 24px;
      }
    }
  
    .link {
      button {
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
  
        font-size: 20px;
        line-height: 24px;
        
        border: none;
        width: 100%;
        padding: 10px;
  
        background-color: ${({ theme }) => theme.primaryColor};
        color: ${({ theme }) => theme.color.white};
    
        svg {
          visibility: hidden;
          opacity: 0;
          transition: visibility 0s, opacity 0.5s, margin-right 0.2s linear;
          margin-right: 0;
        }
        
        &:hover {
          cursor: pointer;
        }
      }
    }

    &:hover {
      box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
        0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -7px rgba(0, 0, 0, 0.2);
      
      .link {
        svg {
          visibility: visible;
          opacity: 1;
          margin-right: 10px;
        }
      }
    }
  }
`
