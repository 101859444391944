import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
// import components
import SectionMap from "components/SectionMap"

const ParagraphGoogleMap = ({
  content: { fieldTitle, fieldDescription, fieldLatitude, fieldLongitude, fieldLink, fieldMediaImage },
}) => (
  <SectionMap
    title={fieldTitle}
    description={fieldDescription}
    latitude={fieldLatitude}
    longitude={fieldLongitude}
    image={fieldMediaImage?.entity.fieldMediaImage.map.url}
    linkUrl={fieldLink.url.path}
    linkText={fieldLink.title}
  />
)

export const fragment = graphql`
  fragment ParagraphGoogleMap on Drupal_ParagraphGoogleMap {
    fieldTitle
    fieldDescription
    fieldLatitude 
    fieldLongitude 
    fieldLink {
      url {
        path
      }
      title
    }
    fieldMediaImage {
      entity {
        ...MediaImage
      }
    }
  }
`

ParagraphGoogleMap.propTypes = {
  content: PropTypes.object,
}

export default ParagraphGoogleMap
