import React from "react"
import PropTypes from "prop-types"
import { Button } from "antd"
import StyledButton from "./styles"

const ButtonCustom = ({
  link,
  children,
  size,
  type,
  onclick,
  htmlType,
  loading,
}) => (
  <StyledButton className="component">
    <Button
      type={type}
      size={size}
      href={link}
      onClick={onclick}
      htmlType={htmlType}
      loading={loading}
    >
      {children}
    </Button>
  </StyledButton>
)

ButtonCustom.propTypes = {}

export default ButtonCustom
