import styled from "styled-components"

export default styled.div`
  .icon {
    background-color: #262227;
    width: 50px;
    height: 50px;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 5px 5px rgba(0, 0, 0, 0.3);
    margin-right: 20px;
    
    svg {
      color: ${({ theme }) => theme.color.white};
      font-size: 25px;
    }
  }
`
