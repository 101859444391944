import React from "react"
import PropTypes from "prop-types"
import { Collapse } from "antd"
import StyledAccordions from "./styles"

const Accordions = ({ listAccordions }) => (
  <StyledAccordions className="component">
    <div className="container">
      <Collapse accordion>{listAccordions}</Collapse>
    </div>
  </StyledAccordions>
  )

Accordions.propTypes = {
  listAccordions: PropTypes.array,
}

Accordions.defaultProps = {
  listAccordions: [],
}

export default Accordions
