import styled from "styled-components"

export default styled.div`
  background-color: ${({ theme }) => theme.primaryColor};
  color: ${({ theme }) => theme.color.white};
  
  .text {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    padding: 20px 0;
  }
`
