import React from "react"
import PropTypes from "prop-types"
import { Row } from "antd"
import StyledCopryright from "./styles"

const Copyright = ({ title }) => (
  <StyledCopryright>
    <Row type="flex" justify="center" align="middle">
      <div className="text">{title}</div>
    </Row>
  </StyledCopryright>
  )

Copyright.propTypes = {
  title: PropTypes.string,
}

export default Copyright
