import styled from "styled-components"

export default styled.div`
  padding-top: 30px;
  
  form {
    .ant-form-item-label {
      label {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        font-family: ${({ theme }) => theme.fontFamily.main};
      }
    }
  }
  
`
