import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
// import components
import Contact from "components/Contact"
import Newsletter from "components/Newsletter"
import ActusTapuFR from "templates/views/ViewsListActuTapu"
import ActusTapuEn from "templates/views/ViewsListActuTapuEn"

const ParagraphBlockGatsby = ({content}) => {
  const block = content.fieldBlockGatsby

  switch(block) {
    case 'contact':
      return <Contact />
    case 'newsletter':
      return <Newsletter />
    case 'actus_tapu_fr':
      return <ActusTapuFR />
    case 'actus_tapu_en':
      return <ActusTapuEn />
    default:
      break;
  }
}

export const fragment = graphql`
  fragment ParagraphBlockGatsby on Drupal_ParagraphBlockGatsby {
    fieldBlockGatsby
  }
`

ParagraphBlockGatsby.propTypes = {
  content: PropTypes.object,
}

export default ParagraphBlockGatsby
