import React from "react"
import { Carousel } from 'antd';
import StyledSlider from "./styles"

const Slider = ({ children }) => (
  <StyledSlider>
    <Carousel autoplay effect="fade">
      {children}
    </Carousel>
  </StyledSlider>
)

export default Slider
