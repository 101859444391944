import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
// import components
import Copyright from "components/Copyright"

const ParagraphCopyright = ({ content: { fieldTitle } }) => (
  <Copyright title={fieldTitle} />
)

export const fragment = graphql`
  fragment ParagraphCopyright on Drupal_ParagraphCopyright {
    fieldTitle
  }
`

ParagraphCopyright.propTypes = {
  content: PropTypes.object,
}

export default ParagraphCopyright
