import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Col, Row } from "antd"
// import components
import Cards from "components/Cards"
import Card from "components/Cards/Card"

const ParagraphCards = ({ content: { fieldTitle, fieldCard } }) => {
  const cards = fieldCard.map(card => (
    <Col lg={8} md={12} sm={24} xs={24} key={card.entity.entityId}>
      <Card
        title={card.entity.fieldTitle}
        subtitle={card.entity.fieldSubtitle}
        imageUrl={card.entity.fieldMediaImage?.entity.fieldMediaImage.card.url}
        linkUrl={card.entity.fieldLink?.uri}
        linkText={card.entity.fieldLink?.title}
      />
    </Col>
  ))

  const rowCards = (
    <Row type="flex" justify="space-between" gutter={[16, 10]}>
      {cards}
    </Row>
  )

  return <Cards title={fieldTitle} cards={rowCards} />
}

export const fragment = graphql`
  fragment ParagraphCards on Drupal_ParagraphCards {
    fieldTitle
    fieldBackgroundImage
    fieldCard {
      entity {
        ...ParagraphCard
      }
    }
  }
  
  fragment ParagraphCard on Drupal_ParagraphCard {
    fieldTitle
    fieldSubtitle
    fieldLink {
      title
      uri
    }
    fieldMediaImage {
      entity {
        ...MediaImage
      }
    }
  }
`

ParagraphCards.propTypes = {
  content: PropTypes.object,
}

export default ParagraphCards
