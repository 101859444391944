import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
// import components
import Text from "components/Text"

let column = "1"
const ParagraphText = ({ content }) => {
  if (content.fieldTextColumn) {
    column = "2"
  }
  return (
    <Text
      title={content.fieldTitle}
      subtitle={content.fieldSubtitle}
      htmlText={content.fieldText.value}
      columnCount={column}
    />
  )
}

export const fragment = graphql`
  fragment ParagraphText on Drupal_ParagraphText {
    fieldTitle
    fieldSubtitle
    fieldTextColumn
    fieldText {
      value
    }
  }
`

ParagraphText.propTypes = {
  content: PropTypes.object,
}

export default ParagraphText
