import styled from "styled-components"
import Tattoo from "images/TextCallToAction/Section_Donation_Left_Tattoo.png"

export default styled.div`
  margin: 0 auto;
  padding: 100px;
  background-color: ${({ theme }) => theme.color.white};
  max-width: 80%;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.second};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    font-family: ${({ theme }) => theme.fontFamily.main};
    font-size: 36px;
    line-height: 40px;
    color: ${({ theme }) => theme.color.black};
    font-weight: 800;
    position: relative; 
    margin: 0 auto;
    padding-bottom: 15px;
    
    &::after {
      display: block;
      content: "";
      width: 100%;
      height: 2px;
      background-color: rgba(70,70,70,0.4);
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
    
  .content {
    margin: 50px 0;
    font-size: 18px;
    line-height: 24px;
    max-width: 800px;
    font-family: ${({ theme }) => theme.fontFamily.second};
  }
    
  .ant-btn {
     margin-bottom: 0;
  }
    
  @media screen and (max-width: 1023px) {
    padding: 50px;
    
    .title {
      font-size: 36px;
      line-height: 40px;
    }
  }
`

export const Wrapper = styled.div`
  background: url(${Tattoo}) center center;
`

