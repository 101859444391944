import React from "react"
// import PropTypes from 'prop-types';
import imageBar from "images/PageReference/PageReferenceBar.png"
import DefaultHeaderImage from "images/HeaderImage/tevei-renvoye-2PbzxoT4me4-unsplash.jpg"
import StyledPageReference from "./styles"

const PageReference = ({ title, image, linkUrl, content }) => {
  let renderImage

  if (image == null) {
    renderImage = DefaultHeaderImage
  } else {
    renderImage = image
  }

  const listContent = content.map((itemContent, index) => (
    <li>
      <a key={index} href={itemContent.entity.entityUrl.path}>
        {itemContent.entity.title}
      </a>
    </li>
  ))

  return (
    <StyledPageReference
      translate={listContent.length > 0 ? "translateX(50%)" : ""}
      className="component"
      imageUrl={renderImage}
    >
      <div className="container">
        <div className="wrapper">
          <div className="title">{title}</div>

          <div className="content">
            <div className="pattern">
              <img src={imageBar} alt="" className="img-fluid" />
            </div>
            <ul>{listContent}</ul>
          </div>

          <a href={linkUrl} />
        </div>
      </div>
    </StyledPageReference>
  )
}

PageReference.propTypes = {}

export default PageReference
