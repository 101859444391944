import styled from "styled-components"

export default styled.div`
  position: relative;
  
  .wrapper {
    width: 100%;
    margin-bottom: 15px;
    border: 1px solid ${({ theme }) => theme.primaryColor};
    height: 170px;
    position: relative;
    box-shadow: 0 1px 20px rgba(000,000,000,0.3);
    cursor: pointer;
    overflow: hidden;
    background: url('${({ imageUrl }) => imageUrl}') no-repeat center;
    background-size: cover;
    
    > a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
    
    .gatsby-image-wrapper {
      height: 100%;
      width: 100%;
    } 
   
    .title {
      background-color: rgba(0, 0, 0, 0.59);
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 0;
      padding: 10px 40px;
      font-family: ${({ theme }) => theme.fontFamily.second};
      font-size: 20px;
      line-height: 24px;
      color: ${({ theme }) => theme.color.white};
    }
    
    .content {
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 5px;
      background-color: white;
      color: ${({ theme }) => theme.color.black}; 
      transform: translateX(100%);
      transition: transform .4s ease-in-out;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .pattern {
        position: absolute;
        left: -15px;
      }
      
      ul {
        margin: 0;
        width: 65%;
        overflow-y: scroll;

        li a {
          color: ${({ theme }) => theme.color.black};
          margin-bottom: 10px;
        
          &:last-child {
            margin-bottom: 0;
          }
                  
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    
    &:hover {
      .content {
        transform: ${({ translate }) => translate};
      }
    }
  }
`
