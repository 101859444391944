import React from "react"
import PropTypes from "prop-types"
import { Col , Row } from "antd"
import { getParagraph } from "utils/paragraphs"

// import components
import Copyright from "components/Copyright"
import ViewsListAllWebsite from "templates/views/ViewsListAllWebsite"
import {
  StyledCopyright,
  StyledFooter,
} from "components/layouts/Container/styles"

const FooterCustomPage = ({ nodeById }) => {
  const footer = nodeById.fieldSiteInternet?.entity.fieldFooter.entity.fieldContent.map(
    getParagraph,
  )

  return (
    <>
      <StyledFooter>
        <Row type="flex" align="middle">
          <Col lg={12} md={24} sm={24} xs={24}>
            <ViewsListAllWebsite footerDisplay />
          </Col>
          <Col lg={12} md={24} sm={24} xs={24}>
            <div className="footer">
              <div className="contactFooter">
                {footer}
              </div>
            </div>
          </Col>
        </Row>
      </StyledFooter>

      <StyledCopyright>
        <Copyright title="2020 © Direction de la Culture et du Patrimoine" />
      </StyledCopyright>
    </>
  )
}

FooterCustomPage.propTypes = {
  nodeById: PropTypes.object.isRequired,
}

export default FooterCustomPage
