import React from "react"
import { Helmet } from "react-helmet"

const Seo = ({ page, metaData, pageContext }) => {
  const metatags = page.entityMetatags?.map(metatag => {
    const {key, value} = metatag
    switch (key) {
      case "title":
        return <meta key={key} property="og:title" content={value} />
      case "description":
        return <meta key={key} name="description" content={value} />
      default:
      break
    }
  })

  const {language} = pageContext
  const ogLocale = `${language.toLowerCase()}_${language.toUpperCase()}`
  const ogImage = page.fieldOgImage?.entity?.url || null
  const ogUrl = page.entityTranslations?.entityUrl?.path

  return (
    <Helmet>
      <title>{page.title}</title>
      {ogUrl && <link rel="canonical" href={ogUrl} />}
      {metaData.siteName && <meta property="og:site_name" content={metaData.siteName} />}
      {metaData.postalCode && <meta property="og:postal_code" content={metaData.postalCode} />}
      {metaData.email && <meta property="og:email" content={metaData.email} />}
      {metaData.phoneNumber && <meta property="og:phone_number" content={metaData.phoneNumber} />}
      {metaData.type && <meta property="og:type" content={metaData.type} />}
      {ogUrl && <meta property="og:url" content={ogUrl} />}
      {ogLocale && <meta property="og:locale" content={ogLocale} />}
      {ogImage && <meta property="og:image" content={ogImage} />}
      {metatags}
    </Helmet>
  )
}

export default Seo
