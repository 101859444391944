import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
// import components
import Iframe from "components/Iframe"

const ParagraphIframe = ({
 content: {
   fieldIframe: { title, url, width, height },
 },
}) => <Iframe title={title} url={url} width={width} height={height} />

export const fragment = graphql`
  fragment ParagraphIframe on Drupal_ParagraphIframe {
    fieldIframe {
      title
      url
      width
      height
    }
  }
`

ParagraphIframe.propTypes = {
  content: PropTypes.object,
}

export default ParagraphIframe
