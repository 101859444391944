import React, { useState } from "react"
import PropTypes from "prop-types"
import { Form, Input, notification, Row, Col } from "antd"
import ButtonCustom from "components/Button"
import StyledContact from "./styles"
// import component

const Contact = ({ form }) => {
  const [isSubmiting, setIsSubmiting] = useState(false)
  const { getFieldDecorator } = form

  const handleSubmit = e => {
    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err) {
        setIsSubmiting(true)
        form.resetFields()
        notification.success({
          message: "Message envoyé.",
        })
      }
    })
  }
  return (
    <StyledContact>
      <div className="container" style={{ display: "block" }}>
        <Form layout="vertical" name="contact">
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Nom">
                {getFieldDecorator("lastName", {
                  rules: [{ required: true, message: "Nom requis" }],
                  initialValue: "",
                })(<Input placeholder="Nom" />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Prénom">
                {getFieldDecorator("firstName", {
                  rules: [{ required: true, message: "Prénom requis" }],
                  initialValue: "",
                })(<Input placeholder="Prénom" />)}
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Adresse email">
            {getFieldDecorator("email", {
              rules: [
                {
                  required: true,
                  type: "email",
                  message: "Adresse email requise",
                },
              ],
              initialValue: "",
            })(<Input type="Input.Email" placeholder="Adresse email" />)}
          </Form.Item>

          <Form.Item label="Objet">
            {getFieldDecorator("object", {
              rules: [{ required: true, message: "Objet requis" }],
              initialValue: "",
            })(<Input placeholder="Objet" />)}
          </Form.Item>

          <Form.Item label="Message">
            {getFieldDecorator("message", {
              rules: [{ required: true, message: "Message requis" }],
              initialValue: "",
            })(<Input.TextArea placeholder="Message" rows={10} cols={60} />)}
          </Form.Item>

          <Form.Item style={{ textAlign: "center" }}>
            <ButtonCustom
              type="primary"
              htmlType="submit"
              loading={isSubmiting}
              onclick={handleSubmit}
            >
              Envoyer
            </ButtonCustom>
          </Form.Item>
        </Form>
      </div>
    </StyledContact>
  )
}

Contact.propTypes = {
  form: PropTypes.object,
}

export default Form.create({ name: "contact" })(Contact)
