import React from "react"
import PropTypes from "prop-types"
import { getParagraph } from "utils/paragraphs"

const HeaderCustomMobile = ({ nodeById }) => {
  const header = nodeById.fieldSiteInternet.entity.fieldMenu.entity.fieldContent.map(
    content => {
      // eslint-disable-next-line
      content.entity.__typename = "Drupal_ParagraphMainMenuMobile"
      return getParagraph(content)
    },
  )

  return <>{header}</>
}

HeaderCustomMobile.propTypes = {
  nodeById: PropTypes.object.isRequired,
}

export default HeaderCustomMobile
