import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
// import components
import Links from "components/Links"

const ParagraphLinks = ({ content: { fieldTitle, fieldLinks } }) => (
  <Links listLinks={fieldLinks} title={fieldTitle} />
)

export const fragment = graphql`
  fragment ParagraphLinks on Drupal_ParagraphLinks {
    fieldTitle
    fieldLinks {
      title
      url {
        path
      }
    }
  }
`

ParagraphLinks.propTypes = {
  content: PropTypes.object,
}

export default ParagraphLinks
