import React from "react"
import PropTypes from "prop-types"
import { getParagraph } from "utils/paragraphs"
// import components
import LanguageSwitcher from "components/LanguageSwitcher"
import HeaderImage from "components/HeaderImage"
import { StyledHeader } from "components/layouts/Container/styles"
import HeaderCustomMobile from "./HeaderCustomMobile"

const HeaderCustomPage = ({ nodeById }) => {
  const { title } = nodeById
  const isHomepage = nodeById.fieldIsHomepage
  const translations = nodeById.entityTranslations
  const currentURL = nodeById.entityUrl?.path
  const image =
    nodeById.fieldMediaImageHeader?.entity.fieldMediaImage.imageHeader.url

  const languageSwitcher = (
    <LanguageSwitcher currentURL={currentURL} translations={translations} />
  )

  const header = nodeById.fieldSiteInternet?.entity.fieldMenu.entity.fieldContent.map(
    content => {
      if (content.entity.__typename === "Drupal_ParagraphMainMenu") {
        // eslint-disable-next-line
        content.entity.components = languageSwitcher
      }
      return getParagraph(content)
    },
  )

  return (
    <>
      {!isHomepage && <HeaderImage title={title} image={image} />}
      <StyledHeader>{header}</StyledHeader>
    </>
  )
}

HeaderCustomPage.propTypes = {
  nodeById: PropTypes.object.isRequired,
}

export default HeaderCustomPage
