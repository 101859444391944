import styled from "styled-components"

export default styled.div`
  .title {
    font-size: 50px;
    line-height: 56px;
    font-family: ${({ theme }) => theme.fontFamily.bigTitle};
    margin-bottom: 20px;
  }
  
  .links {
    display: flex;
    font-family: ${({ theme }) => theme.fontFamily.second};
    font-size: 18px;
    line-height: 24px;
    flex-direction: column;
    margin: 20px 0;
    padding: 5px 0;

    a {
      color: ${({ theme }) => theme.color.black};
    }
  }
`
