import styled from "styled-components"
import ImageFilled from "images/SliderCarousel/slick-button-filled.png"

export default styled.div`
  .ant-row-flex {
    .info-section {
      background-color: ${({ theme }) => theme.color.black};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: ${({ theme }) => theme.color.white};
      padding: 20px;
      
      .title {
        font-family: Segoe UI Black, sans-serif;
        font-size: 26px; 
        line-height: 30px;
        font-weight: 600;
        margin-bottom: 20px;
      }

      .text {
        max-width: 350px;
        overflow-wrap: break-word;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        font-family: ${({ theme }) => theme.fontFamily.second};
      }
    }
  }
    .img-section {
      .slick-list {
        max-height: 400px;
      }
      
      .slick-dots {
        bottom: 40px;
        width: 100%;
        text-align: center;
        
        ul {
        padding: 0;
        margin: 0;
        
          li {
            margin: 0 16px;
              
            button:before {
              height: 35px;
              width: 35px;
              color: transparent;
              background-size: cover;
              background: black url(${ImageFilled}) no-repeat center;
            }
          }
        }
      }
    }
  }
`
