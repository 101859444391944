import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Icon from "components/Icon"
import Icons from "components/Icons"
import { FaFacebookF } from "react-icons/fa"

const fb = <FaFacebookF />

const ParagraphIcons = ({ content: { fieldTitle, fieldIcons } }) => {
  const icons = fieldIcons.map(result => (
    <Icon url={result.entity.fieldLink.uri} key={result.entity.entityId} icons={fb} />
  ))

  return <Icons title={fieldTitle} icons={icons} />
}

export const fragment = graphql`
  fragment ParagraphIcons on Drupal_ParagraphIcons {
    fieldTitle
    fieldIcons {
      entity {
        ...ParagraphIcon
      }
    }
  }

  fragment ParagraphIcon on Drupal_ParagraphIcon {
    entityId
    fieldLink {
      uri
    }
  }
`

ParagraphIcons.propTypes = {
  content: PropTypes.object,
}

export default ParagraphIcons
