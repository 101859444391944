import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
// import components
import Slider from "components/Slider"
import SliderItem from "components/Slider/SliderItem"

const ParagraphSlider = ({ content: { fieldSliderItems } }) => {
  const items = fieldSliderItems?.map(item => (
    <SliderItem
      key={item.entity.entityId}
      title={item.entity.fieldTitle}
      subtitle={item.entity.fieldSubtitle}
      image={item.entity.fieldMediaImage.entity.fieldMediaImage.slider.url}
    />
  ))

  return (
    <Slider>
      {items}
    </Slider>
  )
}

export const fragment = graphql`
  fragment ParagraphSlider on Drupal_ParagraphSlider {
    fieldSliderItems {
      entity {
         ...ParagraphSliderItem
      }
    }
  }
  
  fragment ParagraphSliderItem on Drupal_ParagraphSliderItem {
    entityId
    fieldTitle
    fieldSubtitle
    fieldMediaImage {
      entity { 
         ...MediaImage
      }
    }
  }
`

ParagraphSlider.propTypes = {
  content: PropTypes.object,
}

export default ParagraphSlider
