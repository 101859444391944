import styled from "styled-components"

export default styled.div`
 .title {
    font-size: 50px;
    line-height: 56px;
    font-family: ${({ theme }) => theme.fontFamily.bigTitle};
    margin-bottom: 20px; 
  }
`
