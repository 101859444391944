import React from "react"
import { Row, Col, Carousel } from "antd"
// import images
import SliderCarouselStyles from "./styles"

const SliderCarousel = ({ title, description, images }) => (
  <SliderCarouselStyles>
    <Row type="flex">
      <Col md={8} xs={24} className="info-section">
        {title && <div className="title">{title}</div>}
        {description && <div className="text">{description}</div>}
      </Col>
      <Col md={16} xs={24} className="img-section">
        <Carousel autoplay effect="fade">
          {images}
        </Carousel>
      </Col>
    </Row>
  </SliderCarouselStyles>
)

export default SliderCarousel
