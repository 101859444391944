import Img from "gatsby-image"
import React from "react"
import DefaultHeaderImage from "images/HeaderImage/tevei-renvoye-2PbzxoT4me4-unsplash.jpg"
import StyledHeaderImage from "./style"

const HeaderImage = ({ title, image }) => {
  let renderImage;

  if (image == null) {
    renderImage = DefaultHeaderImage
  } else {
    renderImage = image
  }

  return (
    <StyledHeaderImage imageUrl={renderImage}>
      <div className="content">
        {title && (
          <div className="title">
            <h1>{title}</h1>
          </div>
        )}
      </div>

      <div className="styledBar" />
    </StyledHeaderImage>
  )
}

export default HeaderImage
