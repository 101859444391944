import styled from "styled-components"

export default styled.div`
  width: 100%;
  
  .mega-menu {
    display: none;
    width: 100%;
    
    .ant-row-flex {
       min-height: 350px;
    }
    
    z-index: 100;
    padding: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    box-shadow: 3px 3px 6px rgba(0,0,0,0.16);
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    
    .image-bg {
      background-image: url(${({ imageUrl }) => imageUrl});
      background-size: cover;
      height: 100%;
    }
    
    .bg {
      min-height: 300px;
      height: 100%;
      width: 100%;
      background-image: url('${({ backgroundImage }) => backgroundImage}');
      background-size: cover;
    }
    
    .tatoo { 
      background: url('${({ tabsImage }) => tabsImage}') no-repeat right bottom; 
    }
    
    .mega-menu-content {
      overflow: hidden;
      padding: 20px;
      
      .title {
       font-size: 22px;
       line-height: 26px;
       margin-bottom: 10px;
       
       a {
        color: ${({ theme }) => theme.color.primaryColor}; 
       }
      }
      
      .content {
        a {
          display: block;
          color: ${({ theme }) => theme.color.black}; 
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 5px;
        }
      }
    }
  }
`;
